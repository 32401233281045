@include selection($selection-bg: #DCE7AF, $selection-c: #fff, $selection-ts: none);

.lightgray {
	color: #535353
}

.social {
	background: #F1F7F1 url(/img/social-bg.png) no-repeat center;
	padding: 21px 0 14px;
	// margin: 20px 0;
	border-top: 1px solid $border-color;
	border-bottom: 1px solid $border-color;

	.addthis_sharing_toolbox {
		height: 25px;
	}
}
