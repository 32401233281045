#header {
	background: #FFF;
	border-bottom: 1px solid $border-color;
	position: fixed;
	top: 0;
	width: 100%;
	line-height: 100px;
	height: 100px;
	font-family: 'Baron Neue';
	text-transform: lowercase;
	font-size: 1.4rem;
	z-index: 99;
}

.gh-logo {
	width: 121px;
	height: 45px;
}

.gh-item {
	display: inline-block;

	&:hover {
		.gh-subnav {
			border-bottom: 1px solid $border-color;
			max-height: 100px;
			-webkit-transition: max-height 0.2s linear;
			-moz-transition: max-height 0.2s linear;
			transition: max-height 0.2s linear;
		}
	}
}

.gh-link {
	display: inline-block;
	text-decoration: none;
	border-top: 2px solid transparent;
	border-bottom: 2px solid transparent;
	cursor: pointer;
	color: $font-color;
	padding: 0 22px;
	line-height: 96px;

	&:hover,
	&.active {
		color: $green;
		border-color: $green;
	}
}

#header-wrapper {
	height: 100px;
}

#nav {
	display: inline-block;
	margin-left: 30px;
}

.gh-phone {
	float: right;
	border-left: 1px solid $border-color;
	padding-left: 15px;
	font-size: 1.2rem;
}

.gh-subnav {
	overflow: hidden;
	position: absolute;
	top: 100px;
	left: 0;
	right: 0;
	text-align: center;
	background: #FFF;

	max-height: 0;
	-webkit-transition: max-height 0.2s linear;
	-moz-transition: max-height 0.2s linear;
	transition: max-height 0.2s linear;
	-webkit-transition-delay: 0.2s;
	-moz-transition-delay: 0.2s;
	transition-delay: 0.2s;

	.gh-link {
		padding: 0 20px;

		&:hover,
		&.active {
			border-top-color: transparent;
			border-bottom-color: transparent;
		}
	}
}

.gh-collapse {
	display: none;
	position: absolute;
	right: 10px;
	top: 0;

	font-family: 'Gill Sans';
	text-decoration: none;
	text-transform: none;
	font-size: 1.6rem;

	.icon {
		margin-left: 5px;
		image-rendering: pixelated;
	}
}

.gh-phone {
	a {
		text-decoration: none;
	}
}
