#banner {
	display: table;
	width: 100%;
	height: 379px;
	background: center no-repeat;
	text-align: center;
	max-width: 100%;
	margin-top: 100px;

	h2 {
		display: table-cell;
		vertical-align: middle;
		text-align: center;
		padding-bottom: 37px;
		font-size: 2.4rem;
		line-height: 30px;
		color: #F1F1F1;
		text-shadow: 0px 2px 1px rgba(27, 80, 26, 1);
		padding-left: 10px;
		padding-right: 10px;
	}

	a {
		text-decoration: none;

		&:hover {
			color: inherit;
			text-decoration: underline;
		}
	}
}

.banner-compact {
	h2 {
		display: block !important;
		line-height: 140px !important;
	}
}
