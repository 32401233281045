.button {
	display: inline-block;
	padding: 0 35px 0 15px;
	min-width: 220px;
	margin: 0 auto;
	position: relative;

	height: 44px;

	border-radius: 2px;
	border-bottom-right-radius: 2px 4px;
	border-bottom-left-radius: 2px 4px;

	border: none;
	border-bottom: 2px solid #71A12E;

	background: #A6D071;
	color: #FFF;
	font-family: 'Baron Neue';
	text-transform: lowercase;
	font-size: 1.4rem;
	line-height: 4.5rem;

	text-align: left;
	text-decoration: none;

	transition: .2s background-color;

	&:hover {
		color: #FFF;
		background: #71A12E;
	}
	&:after {
		content: '';
		position: absolute;
		top: 50%;
		margin-top: -(map-get($icon-next, 'height')/2);

		right: 15px;
		@include sprite($icon-next);
	}
}
