.content-blog {
	p,
	ul,
	ol {
		margin: 0 0 1.3em
	}

	padding: 20px 15px !important;
}

.blog-info {
	border: 1px solid $border-color;
	border-top: none;
	line-height: 40px;
	font-size: 1.3rem;
	color: #A9A9A9;
	margin-bottom: 25px;
	@include clearfix();

	.icon {
		vertical-align: -1px;
		margin-right: 5px;
	}
}

.blog-info-compact {
	border-left: none;
	border-right: none;
}

.blog-date {
	float: right;
	border-left: 1px solid $border-color;
	background: #F1F7F1;
	width: 140px;
	text-align: center;
}

.blog-author {
	float: left;
	text-align: left;
	padding-left: 3%;
}

.blog-body {
	padding: 0 20px;
	color: #A9A9A9;
}

.blog-overview {
	@include clearfix();

	a {
		text-decoration: none;

		&:hover {
			color: inherit;
		}
	}

	.blog-author {
		padding-left: 15px;
	}
}

.blog-more {
	color: $green;
	text-decoration: underline;

	&:hover {
		color: darken($green, 10);
	}
}

.blog-article {
	border: 1px solid $border-color;
	margin: 2%;
	text-align: left;
	background: url(/img/blog-bg.png) no-repeat right bottom;
}

.blog-summary {
	margin: 20px 5%;
}

.blog-thumbnail {

	img {
		width: 100%;
	}
}

.blog-nav {
	display: inline-block;
	border: 2px solid $border-color;
	border-right: none;
	margin: 40px 0;
	font-size: 0;
}

.bn-item {
	display: inline-block;
	height: 27px;
	line-height: 27px;
	border-right: 2px solid $border-color;
	font-size: 1.4rem;
	text-decoration: none;

	.icon {
		vertical-align: sub;
	}

	&.bn-active,
	&:hover {
		color: $green !important;

		.bn-prev .icon {
			background-position: map-get($icon-prev-active, 'offset-x') map-get($icon-prev-active, 'offset-y');
		}

		.bn-next .icon {
			background-position: map-get($icon-next-active, 'offset-x') map-get($icon-next-active, 'offset-y');
		}
	}
}

.bn-label {
	padding: 0 20px;
}

.bn-overview {
	padding: 0 40px;

	.icon {
		margin-right: 6px;
		vertical-align: -1px;
	}

	&:hover {
		.icon {
			background-position: map-get($icon-grid-active, 'offset-x') map-get($icon-grid-active, 'offset-y');
		}
	}
}

.bn-pagination {
	.bn-item {
		width: 27px;
	}
}

.bn-switch {
	.bn-next {
		display: inline-block;
		border-left: 2px solid $border-color;
		width: 27px;
	}

	.bn-prev {
		display: inline-block;
		border-right: 2px solid $border-color;
		width: 27px;
	}
}
