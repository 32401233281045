@font-face {
	font-family: 'Baron Neue';
	src: url('/fonts/Baron_Neue_Normal_50.eot');
	src: url('/fonts/Baron_Neue_Normal_50.eot?#iefix') format('embedded-opentype'),
		 url('/fonts/Baron_Neue_Normal_50.woff') format('woff'),
		 url('/fonts/Baron_Neue_Normal_50.ttf') format('truetype'),
		 url('/fonts/Baron_Neue_Normal_50.svg#font-name') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Gill Sans';
	src: url('/fonts/gillsans.eot');
	src: url('/fonts/gillsans.eot?#iefix') format('embedded-opentype'),
		 url('/fonts/gillsans.woff') format('woff'),
		 url('/fonts/gillsans.ttf') format('truetype'),
		 url('/fonts/gillsans.svg#font-name') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Gill Sans';
	src: url('/fonts/gill-sans-mt-italic.eot');
	src: url('/fonts/gill-sans-mt-italic.eot?#iefix') format('embedded-opentype'),
		 url('/fonts/gill-sans-mt-italic.woff') format('woff'),
		 url('/fonts/gill-sans-mt-italic.ttf') format('truetype'),
		 url('/fonts/gill-sans-mt-italic.svg#font-name') format('svg');
	font-weight: normal;
	font-style: italic;
}
