#main {
	background: #FFF;
	box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, .15);
	margin-top: -37px;
}

.main-up {
	margin-top: -240px !important;
}

.content-spacing {
	padding: 20px 40px;
}

.content-spacing-small {
	padding: 20px;
}

.content-spacing-extra {
	padding: 45px 0;
}

.content-spacing-below {
	padding: 30px 0 40px;
}

.content-border {
	border-top: 1px solid $border-color;
}

.content-contactform {
	padding: 0 0 25px;
}

.content-contactinfo {
	a {
		text-decoration: none !important;
	}
}

.content-main {

	h1 {
		text-align: center;
	}

	ul {
		list-style-type: none;
		padding: 7px 0;
		margin: 0;

		li {
			&:before {
				content: '•';
				color: #93C034;
				margin-right: 8px;
			}
		}
	}
}

.content-home {
	p:last-child {
		a:last-child {
			color: $green;

			&:hover {
				color: darken($green, 5)
			}
		}
	}
}

.content-quote {
	p {
		font-style: italic;
		width: 65%;
		margin: 0 auto;
		font-size: 2rem;
		line-height: 2.6rem;

		&:before {
			content: '“ ';
			color: $green;
		}

		&:after {
			content: ' “';
			color: $green;
		}
	}
}

.content-contact {
	h3 {
		margin: 15px 0 10px;
	}

	p {
		margin: 0 0 1em;
	}
}

.content-facilities {
	background: #F0F6EF url(/img/facilities-bg.png) no-repeat top center;

	ul {
		list-style-type: none;
		padding: 0;
		margin-right: 15px;
	}

	li {
		background: url(/img/icon-checkmark.png?v2) no-repeat left 1px;
		padding: 0 0 0 30px;
		margin: 0 0 25px;
		line-height: 20px;
	}

	small {
		display: block;
		position: absolute;
		font-size: 11px;
		line-height: 14px;
	}
}

.facilities-container {
	text-align: left;
	@include clearfix();
	margin-bottom: 10px;
}

.content-offer {
	p {
		margin: 0 auto;
		width: 71%;
	}
}

.content-offers {
	margin: 30px 0 15px;
}

.co-item {
	text-decoration: none;
	display: inline-block;
	width: 318px;
	height: 168px;
	color: #FFF;
	margin-bottom: 15px;

	h3 {
		text-transform: lowercase;
		font-family: 'Baron Neue';
		font-weight: 400;
		font-size: 2.4rem;
		line-height: 30px;
		color: #FFF;
		text-shadow: 0 2px 1px rgba(27, 80, 26, 1);
		margin: 65px 0 0;
	}

	.icon {
		margin-left: 5px;
	}

	span {
		font-size: 1.8rem;
		font-style: italic;
	}

	&:hover {
		color: #FFF;
		background-position: 0 -168px;

		span {
			text-decoration: underline;
		}
	}
}

.co-werkplekken {
	background: url(/img/offer-werkplekken.jpg);
}
.co-werkunits {
	background: url(/img/offer-werkunits.jpg);
}
.co-vergaderruimtes {
	background: url(/img/offer-vergaderruimtes.jpg);
}
