input[type="checkbox"],
input[type="radio"],
select {
	cursor: pointer;
}

.form-input,
.form-textarea {
	width: 100%;
	border: 1px solid #E1F2EB;
	margin: 0 0 10px;
	height: 34px;
	font-family: Gill Sans;
	font-size: 1.5rem;
	padding: 0 11px;
	color: $font-color;
	border-radius: 0;
	background: #FFF;

	// remove inner shadow on iOS
	-webkit-appearance: none;

	&:focus {
		outline: 0;
		border-color: #B3E1CD;
	}
}

.form-textarea {
	height: 132px;
	padding: 9px 11px;
	line-height: 1.3;
}

.form-label {
	color: $green;
	margin-right: 5px;
}

.form-arrows {
	position: relative;

	&:after {
		content: '';
		position: absolute;
		right: 10px;
		top: 10px;

		@include sprite($icon-select);
	}
}

.form-datepicker {
	cursor: pointer;
}

.form-choice-wrapper {
	margin: 10px 0 18px;
	line-height: 1;

	small {
		float: right;
		margin: 4px 0 0;
	}
}

.form-additional {
	float: left;
	font-size: 1.2rem;
	margin-top: 8px;
}

#form-reservation {
	@include clearfix();

	button {
		margin-top: -7px;
	}
}

.error {
	border-color: red !important;
}

.valid {
	border-color: $green !important;
}

#Kr6vhA89Je {
	display: none;
}
