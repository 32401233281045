.container {
	max-width: 1020px;
	width: 100%;
	margin: 0 auto;
	padding: 0 10px;
}

.text-left {
	text-align: left;
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

.left {
	float: left;
}

.right {
	float: right;
}

.icon {
	display: inline-block;
	vertical-align: middle;
}

@include sprites(map-get($spritesheet, 'sprites'));

.row {

	margin-left: -15px;
	margin-right: -15px;

	@include clearfix();

	.col-6 {
		padding-left: 15px;
		padding-right: 15px;
	}
}

// grid
.col-3 {
	float: left;
	width: 25%;
}

.col-4 {
	float: left;
	width: 33.333333333%;
}

.col-6 {
	float: left;
	width: 50%;
}

#google_maps {
	height: 345px;
	background: #E9E5DC;
}
