/*
SCSS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: map-get($icon-home, 'width');
}

At the bottom of this section, we provide information about the spritesheet itself
*/
$icon-arrow: (
  name: 'icon-arrow',
  x: 13px,
  y: 37px,
  offset-x: -13px,
  offset-y: -37px,
  width: 4px,
  height: 9px,
  total-width: 65px,
  total-height: 50px,
  image: '/img/sprite.png?v3'
);
$icon-bars: (
  name: 'icon-bars',
  x: 32px,
  y: 12px,
  offset-x: -32px,
  offset-y: -12px,
  width: 16px,
  height: 10px,
  total-width: 65px,
  total-height: 50px,
  image: '/img/sprite.png?v3'
);
$icon-cal: (
  name: 'icon-cal',
  x: 14px,
  y: 23px,
  offset-x: -14px,
  offset-y: -23px,
  width: 13px,
  height: 13px,
  total-width: 65px,
  total-height: 50px,
  image: '/img/sprite.png?v3'
);
$icon-edit: (
  name: 'icon-edit',
  x: 0px,
  y: 23px,
  offset-x: 0px,
  offset-y: -23px,
  width: 14px,
  height: 14px,
  total-width: 65px,
  total-height: 50px,
  image: '/img/sprite.png?v3'
);
$icon-envelope: (
  name: 'icon-envelope',
  x: 32px,
  y: 0px,
  offset-x: -32px,
  offset-y: 0px,
  width: 19px,
  height: 12px,
  total-width: 65px,
  total-height: 50px,
  image: '/img/sprite.png?v3'
);
$icon-grid-active: (
  name: 'icon-grid-active',
  x: 27px,
  y: 23px,
  offset-x: -27px,
  offset-y: -23px,
  width: 13px,
  height: 13px,
  total-width: 65px,
  total-height: 50px,
  image: '/img/sprite.png?v3'
);
$icon-grid: (
  name: 'icon-grid',
  x: 0px,
  y: 37px,
  offset-x: 0px,
  offset-y: -37px,
  width: 13px,
  height: 13px,
  total-width: 65px,
  total-height: 50px,
  image: '/img/sprite.png?v3'
);
$icon-next-active: (
  name: 'icon-next-active',
  x: 51px,
  y: 16px,
  offset-x: -51px,
  offset-y: -16px,
  width: 7px,
  height: 16px,
  total-width: 65px,
  total-height: 50px,
  image: '/img/sprite.png?v3'
);
$icon-next-gray: (
  name: 'icon-next-gray',
  x: 51px,
  y: 32px,
  offset-x: -51px,
  offset-y: -32px,
  width: 7px,
  height: 16px,
  total-width: 65px,
  total-height: 50px,
  image: '/img/sprite.png?v3'
);
$icon-next: (
  name: 'icon-next',
  x: 51px,
  y: 0px,
  offset-x: -51px,
  offset-y: 0px,
  width: 7px,
  height: 16px,
  total-width: 65px,
  total-height: 50px,
  image: '/img/sprite.png?v3'
);
$icon-phone: (
  name: 'icon-phone',
  x: 0px,
  y: 0px,
  offset-x: 0px,
  offset-y: 0px,
  width: 17px,
  height: 23px,
  total-width: 65px,
  total-height: 50px,
  image: '/img/sprite.png?v3'
);
$icon-pin: (
  name: 'icon-pin',
  x: 17px,
  y: 0px,
  offset-x: -17px,
  offset-y: 0px,
  width: 15px,
  height: 23px,
  total-width: 65px,
  total-height: 50px,
  image: '/img/sprite.png?v3'
);
$icon-prev-active: (
  name: 'icon-prev-active',
  x: 58px,
  y: 0px,
  offset-x: -58px,
  offset-y: 0px,
  width: 7px,
  height: 16px,
  total-width: 65px,
  total-height: 50px,
  image: '/img/sprite.png?v3'
);
$icon-prev-gray: (
  name: 'icon-prev-gray',
  x: 58px,
  y: 16px,
  offset-x: -58px,
  offset-y: -16px,
  width: 7px,
  height: 16px,
  total-width: 65px,
  total-height: 50px,
  image: '/img/sprite.png?v3'
);
$icon-prev: (
  name: 'icon-prev',
  x: 58px,
  y: 32px,
  offset-x: -58px,
  offset-y: -32px,
  width: 7px,
  height: 16px,
  total-width: 65px,
  total-height: 50px,
  image: '/img/sprite.png?v3'
);
$icon-select: (
  name: 'icon-select',
  x: 40px,
  y: 23px,
  offset-x: -40px,
  offset-y: -23px,
  width: 10px,
  height: 13px,
  total-width: 65px,
  total-height: 50px,
  image: '/img/sprite.png?v3'
);
$spritesheet: (
  width: 65px,
  height: 50px,
  image: '/img/sprite.png?v3',
  sprites: ($icon-arrow, $icon-bars, $icon-cal, $icon-edit, $icon-envelope, $icon-grid-active, $icon-grid, $icon-next-active, $icon-next-gray, $icon-next, $icon-phone, $icon-pin, $icon-prev-active, $icon-prev-gray, $icon-prev, $icon-select, )
);

/*
The provided mixins are intended to be used with variables directly

.icon-home {
  @include sprite-width($icon-home);
}

.icon-email {
  @include sprite($icon-email);
}
*/
@mixin sprite-width($sprite) {
  width: map-get($sprite, 'width');
}

@mixin sprite-height($sprite) {
  height: map-get($sprite, 'height');
}

@mixin sprite-position($sprite) {
  background-position: map-get($sprite, 'offset-x') map-get($sprite, 'offset-y');
}

@mixin sprite-image($sprite) {
  background-image: url(map-get($sprite, 'image'));
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of SCSS

@include sprites(map-get($spritesheet, 'sprites'));
*/
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: map-get($sprite, 'name');
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
