html {
	box-sizing: border-box;
	font-size: 62.5%;
}

* {
	box-sizing: inherit;
	-webkit-font-smoothing: antialiased;
	font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}

body {
	font: 400 16px/1.6 'Gill Sans', sans-serif;
	background: #F1F8F2 url(/img/bg-pattern.png) center;
	color: $font-color;
	min-width: 280px;
}

img {
	vertical-align: middle;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	text-transform: lowercase;
	font-family: 'Baron Neue';
	font-weight: normal;
	color: $green;
}

h1,
h2 {
	font-size: 1.8rem;
	margin: 0;
}

h3 {
	font-size: 1.6rem;
}

a {
	text-decoration: underline;
	color: inherit;

	&:hover {
		color: $green;
	}
}

hr {
	display: block;
	border-top: 1px solid #CCC;
	border-bottom: 0;
	margin: 1em 0;
}

blockquote {
	font-weight: bold;
	margin: 0;

	&:before {
		content: '“ ';
		color: $green;
	}

	&:after {
		content: ' “';
		color: $green;
	}
}

label {
	cursor: pointer;
}

small {
	font-size: 10px;
}
