@media only screen and (max-width: 995px) {
	// compress navigation
	.gh-link {
		padding: 0 15px;
	}

	// compact facilities slider
	.facilities-content {
		h2 {
			line-height: 1;
		}

		p {
			margin: 0;
		}
	}

	.form-choice-wrapper {
		small {
			margin-top: 10px;
			float: none;
			display: block;
		}
	}
}

@media only screen and (max-width: 930px) {
	// compact facilities slider
	.facilities-content {
		position: relative;
		margin-top: -10px;
		width: 100%;
		height: auto;
		min-height: 0;
		padding-bottom: 60px;
		background-size: auto 100%;

		p {
			margin-top: 6px;
		}

		h2 {
			line-height: 1.6;
		}
	}

	.facilities-image {
		width: 100%;
		float: none;
	}

	.contanct-facilities-wrapper {
		.bx-controls-direction {
			bottom: 15px;
			left: 0;
			right: 0;
			margin: 0 auto;
		}
	}
}

@media only screen and (max-width: 915px) {
	// compress navigation
	.gh-link {
		padding: 0 10px;
	}

	// Spread footer navigation
	.footer-nav {
		margin: 0 15px;
		.col-3 {
			width: 50%;
			margin: 7px 0;
		}
	}
}

@media only screen and (max-width: 860px) {
	// hide phonenumber from navigation
	.gh-phone {
		display: none;
	}
}

@media only screen and (max-width: 720px) {
	// enable mobile navigation
	#header {
		height: 80px;
		line-height: 80px;
	}

	#banner {
		margin-top: 80px;
	}

	#nav {
		background: #FFF;
		position: absolute;
		top: 80px;
		left: 0;
		right: 0;
		margin: 0;
		max-height: 0;
		overflow: hidden;
		-webkit-transition: max-height .4s ease-in-out;
		-ms-transition: max-height .4s ease-in-out;
		transition: max-height .4s ease-in-out;
	}

	.gh-open {
		max-height: 350px !important;
	}

	.gh-done {
		overflow: visible !important;
		max-height: 0;
	}

	.gh-item {
		display: block;

		&:hover {
			.gh-subnav {
				max-height: 300px !important;
				border-top: 1px solid $border-color;
				margin: 0 10px;
			}
		}
	}

	.gh-link {
		display: block;
		width: 100%;
		line-height: 50px;
		padding: 0 20px;

		border: none;
		border-bottom: 1px solid $border-color;

		&:hover,
		&.active {
			border-bottom-color: $border-color;
		}
	}

	.gh-collapse {
		display: block;
	}

	.gh-subnav {
		.gh-link {
			&:hover,
			&.active {
				border-bottom: 1px solid $border-color;
			}
		}
	}

	// compact content
	.content-spacing {
		padding: 20px;
	}

	// compact facilities
	.facilities-container {
		background-size: cover;
		background-position: top right;

		ul {
			margin-bottom: -15px;
		}

		.col-6 {
			width: 100%;
			margin-right: 0;
		}
	}

	.footer-copyright {
		.left,
		.right {
			float: none;
			text-align: left;
			display: block;
		}
	}

	// compact quote
	.content-quote {
		padding: 30px;

		p {
			width: 90%;
		}
	}
}

@media only screen and (max-width: 670px) {
	.blog-overview {
		a {
			width: 100%;
		}
	}

	.content-offer {
		p {
			width: 90%;
		}
	}
}

@media only screen and (max-width: 590px) {
	.bn-switch {

		display: block;
		margin: 20px auto;
		width: 230px;
		border-right: 2px solid $border-color;
		border-bottom: none;

		.bn-item {
			display: block;
			width: 100%;
			border-right: none;
			border-bottom: 2px solid $border-color;
		}

		.bn-prev {
			float: left;
		}

		.bn-next {
			float: right;
		}
	}
}

@media only screen and (max-width: 560px) {
	// Compact footer navigation
	.footer-nav {
		// .col-3 {
		// 	width: 100%;
		// 	margin: 7px 0;
		// }
		display: none;
	}
	.footer-nav-hr {
		display: none;
	}

	// Compact footer contact
	.footer-contact {
		.col-4 {
			width: 100%;
			margin: 7px 0;
		}

		.icon {
			margin-bottom: 5px;
		}
	}

	.content-contact,
	.content-reserve {
		.col-6 {
			width: 100%;
		}
	}

	hr {
		margin-bottom: 20px;
	}

	.form-pick-wrapper {
		margin-bottom: 10px;
	}

}


// @media print, (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {

// }
