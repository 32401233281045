// .content-imageslider {
// 	position: relative;
// 	overflow: hidden;
// }

.slide {
	position: relative;
}


.slider {
	> div {
		display: none;

		&:first-child {
			display: block;
		}
	}
}

.bx-viewport {
	.slider {
		> div {
			display: block;
		}
	}
}

.bx-controls-direction {
	bottom: 19px;
	left: 0;
	right: 0;
	margin: 0 auto;
	width: 58px;
	position: absolute;
	z-index: 1;
	border: 2px solid #FFF;

	a {
		width: 27px;
		height: 27px;
		float: left;
		cursor: pointer;
		user-select: none;
		transition: .2s background-color;
		position: relative;

		&:hover {
			background-color: rgba(255, 255, 255, .25);
		}
	}
}

.bx-prev {
	border-right: 1px solid #FFF;
	// background: url(../img/icon-prev.png) no-repeat center;

	&:after {
		content: '';
		position: absolute;
		left: 50%;
		margin-left: -(map-get($icon-prev, 'width')/2);
		top: 50%;
		margin-top: -(map-get($icon-prev, 'height')/2);
		@include sprite($icon-prev);
	}
}

.bx-next {
	border-left: 1px solid #FFF;
	// background: url(../img/icon-next.png) no-repeat center;

	&:after {
		content: '';
		position: absolute;
		left: 50%;
		margin-left: -(map-get($icon-next, 'width')/2);
		top: 50%;
		margin-top: -(map-get($icon-next, 'height')/2);
		@include sprite($icon-next);
	}
}

.contanct-facilities-wrapper {
	.bx-controls-direction {
		left: 52%;
		right: auto;
		margin: 0;
	}
}

.facilities-background {
	position: absolute;
	top: 0;
	right: 0;
}

.facilities-content {
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	text-align: left;
	float: right;
	background: url(/img/facility-bg.png) repeat-x;
	width: 52%;
	color: #FFF;
	padding: 30px 2% 20px 4%;
	min-height: 262px;

	h2 {
		color: #FFF;
	}

	p {
		margin: 6px 0 0;
	}
}

.facilities-image {
	float: left;
	width: 50%;
}

.content-image-slider {
	img {
		width: 100%;
	}
}
