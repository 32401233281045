#footer {
	background: #3C3C3C;
	padding: 20px 0;
	margin: 50px 0 0;
	color: #E0E0E0;

	hr {
		border-color: #535353;
		margin: 20px 0 30px;
	}

	a {
		text-decoration: none;
	}
}

.footer-contact {
	@include clearfix();

	.icon {
		margin-bottom: 15px;
	}
}

.footer-nav {
	@include clearfix();
	text-align: left;
	margin: 0 40px;
	font-size: 1.4rem;
	line-height: 2.3rem;
}

.fn-item {
	display: block;
}

.footer-copyright {
	color: $font-color;
	margin: 0 20px 10px;

	@include clearfix();
}
